import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA ROLE------
    getAllRole() {
        ApiService.setHeader();
        return ApiService.get("v2/verifikasi/master/role/all")
    },
    getRoleSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/role/single", { params: credentials })
    },
    verifRole(credentials) {
        ApiService.setHeader();
        return ApiService.put(`v2/verifikasi/master/role`, credentials)
    },

}